.banner-top-content {
	position: relative;
	width: 100%;
	min-height: 500px;
	@include respond-to('small') {
		min-height: inherit;
	}
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.3);
		z-index: 1;
	}
}

.banner-top-content__image {
	background-position: center center;
	background-size: cover;
	height: 500px;
	box-shadow: inset 0px -10px 20px 0px rgba(0, 0, 0, 0.15);
	@include respond-to('small') {
		height: 250px;
	}
}

.banner-top-content__title {
	font-size: 50px;
	position: absolute;
	text-align: center;
	top: 50%;
	color: #fff;
	left: 50%;
	transform: translateX(-50%);
	z-index: 5;
	@include respond-to ('small') {
		font-size: 40px;
	}
}

.banner-bred {
	position: relative;
	z-index: 1;
}