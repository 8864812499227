.wrapper-banners {
	position: relative;
	width: 100%;
	overflow: hidden;
	min-height: 400px;
}

.wrap-banner-scroll {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include respond-to('medium') {
		position: static;
	}
}

.banner-item {
	width: 100%;
	min-height: 950px;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	z-index: 2;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.3);
		z-index: 5;
	}
	@include respond-to('huge') {
		min-height: 650px;
	}
	@include respond-to('large') {
		min-height: 450px;
	}
	@include respond-to('extra-small') {
		min-height: 400px;
	}
}

.prev,
.next {
	z-index: 1000;
	position: absolute;
	display: block;
	height: 60px;
	width: 60px;
	line-height: 0px;
	font-size: 0px;
	cursor: pointer;
	background: transparent;
	color: transparent;
	top: 55%;
	transform: translate(0, -50%);
	padding: 0;
	border: none;
	outline: none;
	&:hover,
	&:focus {
		outline: none;
		background: transparent;
		color: transparent;
	}
}

.prev {
	margin-#{$right}: 40px;
	position: absolute;
	z-index: 250;
	@include respond-to ('small') {
		margin-#{$right}: 0;
	}
	&:before {
		content: url('../images/arrow-right.png');
		[dir="rtl"] & {
			content: url('../images/arrow-left.png');
		}
	}
}

.next {
	#{$left}: 0;
	position: absolute;
	z-index: 250;

	@include respond-to ('small') {
		margin-#{$left}: -35px;
	}
	&:before {
		content: url('../images/arrow-left.png');
		[dir="rtl"] & {
			content: url('../images/arrow-right.png');
		}
	}
}

.banner-info {
	text-align: center;
	color: #fff;
	z-index: 100;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 560px;
	width: 100%;
	padding: 0 5px;
	& > .title {
		font-size: 60px;
		color: #fff;
		font-weight: 500;
		margin-bottom: 25px;
		line-height: 1;
		@include respond-to ('medium') {
			font-size: 45px;
		}
		@include respond-to ('extra-small') {
			font-size: 35px;
		}
		@include respond-to ('mobile-l') {
			font-size: 25px;
		}
	}
	& p {
		font-size: 18px;
		white-space: pre-line;
		line-height: 1.2;
		font-weight: 300;
		@include respond-to ('small') {
			font-size: 16px;
			width: 80%;
			margin: 0 auto;
		}
	}
	&__btn {
		margin: 0 auto;
		padding-top: 13px;
		display: block;
		width: 180px;
		height: 55px;
		font-size: 18px;
		font-weight: 500;
		margin-top: 25px;
		background-color: transparent;
		border: 1px solid #ceaf78;
		position: relative;
		z-index: 300;
		@include link_no-hover(#fff);
		&:focus {
			outline: none;
		}
		&:active {
			@include btn-effect-active;
		}
	}
}

.slick-slider {
	z-index: 5;
}
