.ftr-form {
	padding-top: 80px;
	background: #fff;
	height: 370px;
	width: 100%;
	margin-bottom: 35px;
	@include respond-to ('medium') {
		margin-bottom: 100px;
		padding-top: 50px;
	}
	@include respond-to('small') {
		text-align: center;
		margin-bottom: 85px;
		padding-top: 20px;
	}
	@include respond-to ('extra-small') {
		margin-bottom: 125px;
	}
	.has-feedback {
		display: inline-block;
		margin-#{$left}: 29px;
		@include respond-to ('medium') {
			margin-#{$left}: 0;
		}
	}
	.has-error .form-control {
		box-shadow: none;
	}
	.has-success .form-control {
		box-shadow: none;
	}
	.form-group {
		display: inline-block;
		vertical-align: top;
	}
}


.ftr-wrap-input {
	text-align: center;
}

.ftr-form-title {
	margin-bottom: 70px;
	text-align: center;
	color: #636363;
	@include respond-to ('medium') {
		margin-bottom: 50px;
	}
	@include respond-to ('extra-small') {
		margin-bottom: 35px;
	}
	& > p {
		font-size: 40px;
		line-height: 1;
		margin: 0;
		@include respond-to('medium') {
			font-size: 30px;
		}
		& > span {
			font-size: 30px;
			font-weight: 300;
			@include respond-to('medium') {
				font-size: 25px;
			}
		}
	}
}

.newslatter-input {
	width: 370px;
	height: 50px;
	border: none;
	border-bottom: 1px solid #e7d7bb !important;
	border-radius: 0;
	position: relative;
	color: #8e8e8e;
	font-size: 18px;
	box-shadow: none;
	padding: 0;
	@include respond-to('medium') {
		margin: 0 195px;
	}
	@include respond-to('small') {
		margin: 0 auto;
		width: 350px;
	}
	@include respond-to('extra-small') {
		width: 310px;
	}
	@include respond-to('mobile-m') {
		width: 280px;
	}
	&:focus {
		outline: none;
		&::placeholder {
			opacity: 0;
		}
	}
	&::placeholder {
		color: #8e8e8e;
		font-size: 18px;
		transition: opacity 0.4s ease;
	}
}

.newslatter-btn {
	display: block;
	width: 160px;
	height: 50px;
	font-size: 18px;
	color: #636363;
	font-weight: 800;
	border: 0;
	background: #fff;
	border: 1px solid #e7d7bb;
	position: relative;
	@include respond-to('small') {
		margin: 0 100px;
	}
	@include respond-to('extra-small') {
		margin: 15px auto;
	}
	&:focus {
		outline: none;
	}
	&:active {
		@include btn-effect-active;
	}
}

.ftr-check-uncheck {
	position: relative;
	width: 23px;
	height: 23px;
	display: inline-block;
	margin-#{$left}: 5px;
	label {
		width: 23px;
		height: 23px;
		cursor: pointer;
		position: absolute;
		#{$left}: 0;
		top: 0;
		&:after {
			content: '';
			width: 12px;
			height: 6px;
			position: absolute;
			top: 6px;
			#{$left}: 5px;
			border: 2px solid #000;
			border-top: none;
			border-#{$right}: none;
			background: 0 0;
			opacity: 0;
			transform: rotate(-45deg);
		}
	}
	input[type=checkbox] {
		visibility: hidden;
		&:checked + label:after {
			opacity: 1;
		}
	}
}

.has-feedback .form-control {
	padding: 0;
}


.ftr-checkbox-para {
	display: inline-block;
	& > p {
		font-size: 16px;
		font-weight: 300;
		color: #000;
		margin: 0;
		@include respond-to('larger') {
			font-size: 13px;
		}
	}
}
