.ftr-links-koteret {
	font-size: 18px;
	font-weight: 500;
	color: #7f7f7f;
	margin-bottom: 25px;
	padding-bottom: 20px;
	border-bottom: 1px solid #ceaf78;
	position: relative;
	@include respond-to('extra-small') {
		font-size: 14px;
		margin-bottom: 15px;
		padding-bottom: 10px;
	}
	// &:after {
	// 	content: " ";
	// 	position: absolute;
	// 	bottom: -1px;
	// 	left: 0;
	// 	right: 0;
	// 	width: 0%;
	// 	height: 1px;
	// 	background-color: #ceaf78;
	// 	margin: 0 auto;
	// 	transition: all 0.4s;
	// }
}

.ftr-wrap-links {
	max-width: 200px;
	@include respond-to('small') {
		margin-bottom: 30px;
		margin-left: auto;
		margin-right: auto;
	}
	&:hover {
		div:after {
			width: 100%;
		}
	}
	& > ul {
		padding: 0;
		margin: 0;
		list-style: none;
		& > li {
			margin-bottom: 10px;
			& > a {
				font-size: 14px;
				font-weight: 500;
				color: #7f7f7f;
				&:hover {
					color: #ceaf78;
					text-decoration: none;
				}
				&:active {
					text-decoration: none;
				}
			}
		}
	}
}

// @include respond-to('extra-small'){
//   .ftr-wrap-nav{
//     & > .col-xs-6{
//       width: 100%;
//     }
//   }
// }