.hp-offer {
	background: url('../images/bg-offer.jpg');
	background-size: contain;
	width: 100%;
	// min-height: 700px;
	// @include respond-to ('small') {
	// 	height: 1800px;
	// }
	// @include respond-to ('extra-small') {
	// 	height: 1650px;
	// }
	// @include respond-to ('mobile-l') {
	// 	height: 1500px;
	// }
	// @include respond-to ('mobile-m') {
	// 	height: 1305px;
	// }

	&__title {
		color: #fff;
		font-size: 40px;
		padding-top: 85px;
		text-align: center;
		@include respond-to ('small') {
			padding-top: 50px;
			font-size: 30px;
		}
	}
}

.offer {
	text-align: center;
	padding-top: 75px;
	@include respond-to('small') {
		padding-top: 25px;
	}
	&__item {
		display: block;
		@include respond-to('small') {
			max-width: 350px;
			width: 100%;
		}
	}
	&__first {
		position: relative;
		top: 80px;
		float: right;
		text-align: center;
		width: 32%;
		@include respond-to ('small') {
			width: 100%;
			margin: 20px auto;
			float: none;
			top: 0px;
		}
	}
	&__second {
		position: relative;
		float: right;
		margin: 0 27px 0 5px;
		width: 32%;
		@include respond-to ('medium') {
			margin: 0 15px 0 5px;
		}
		@include respond-to ('small') {
			width: 100%;
			margin: 20px auto;
			padding-top: 0;
			float: none;
			top: 0px;
		}
	}
	&__third {
		position: relative;
		float: left;
		top: 80px;
		width: 32%;
		@include respond-to ('small') {
			width: 100%;
			padding-top: 0;
			margin: 20px auto;
			float: none;
			top: 0px;
		}
	}
	& img {
		box-shadow: 0px -30px 17px 0px rgba(0, 0, 0, 0.29) inset, 0px 20px 20px 0px rgba(0, 0, 0, 0.2);
	}
}

.offer-text {
	text-align: center;
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 100%;
	padding: 0 10px;
	transform: translateX(-50%);
	margin-bottom: 10px;
	& .title {
		color: #fff;
		font-size: 30px;
		line-height: 1;
		@include respond-to ('small') {
			font-size: 20px;
		}
	}
	& .link {
		font-weight: 700;
		font-size: 18px;
		color: #ceaf78;
		text-decoration: none;
		@include respond-to ('small') {
			font-size: 16px;
		}
		&:hover, 
		&:active {
			text-decoration: none;
		}
	}
}