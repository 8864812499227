footer {
	width: 100%;
	position: relative;
	z-index: 0;
	padding-top: 100px;
	@include respond-to('large') {
		padding-top: 70px;
	}
}

.ftr-wrap {
	background: url('../images/footer.jpg');
	padding-top: 70px;
	padding-bottom: 20px;
}

.footer-info {
	line-height: 2;
	@include respond-to ('small') {
		text-align: center;
		margin-bottom: 35px;
	}
	& .ftr-logo {
		margin-bottom: 20px;
	}
	& img {
		margin-#{$left}: 20px;
	}
	& a {
		@include link_no-hover(#333);
	}
}

.hr-footer-bottom {
	border: 0;
	border-top: 1px solid #ceaf78;
	margin-top: 40px;
	margin-bottom: 20px;
	@include respond-to('small') {
		margin-top: 10px;
	}
}

.credit-info-txt {
	display: inline-block;
	@include respond-to('medium') {
		display: block;
		text-align: center;
		margin-bottom: 10px;
	}
	@include respond-to('small') {
		margin-#{$right}: 0;
	}
	& > p {
		font-size: 16px;
		color: #000;
		font-weight: 300;
		margin: 0;
		@include respond-to('extra-small') {
			font-size: 14px;
		}
	}
}

.ftr-wrap-nav {
	font-size: 0;
}

.link-col {
	display: inline-block;
	vertical-align: top;
	font-size: initial;
	width: 25%;
	@include respond-to('medium') {
		width: 50%;
		margin-bottom: 15px;
		padding: 0 5px;
	}
}