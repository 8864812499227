.hp-info {
	margin: 10px auto 100px;
	text-align: center;
	@include respond-to ('extra-small') {
		padding-top: 50px;
		margin: 10px auto 0;
	}
	@include respond-to ('mobile-m') {
		padding-top: 75px;
	}
}

.info-box {
	display: inline-block;
	text-align: center;
	width: 24.5%;
	margin: 0 auto;
	padding: 0 10px;
	@include respond-to ('large') {
		width: 49%;
		margin: 20px auto;
	}
	@include respond-to ('small') {
		width: 100%;
	}
	&__title {
		color: #636363;
		font-size: 25px;
		margin-top: 15px;
		@include respond-to ('small') {
			margin-top: 0;
		}
	}
	& p {
		font-size: 18px;
		color: #636363;
		white-space: pre-line;
		margin: 0 auto;
		line-height: 1;
		font-weight: 300;
	}
	& a {
		font-size: 18px;
		color: #ceaf78;
		line-height: 3;
		font-weight: 700;
		&:hover,
		:active {
			text-decoration: none;
		}
	}
}