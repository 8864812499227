.tabs-branches {
	float: #{$left};
	width: 100%;
	max-width: 540px;
	margin-top: 30px;
	padding-bottom: 20px;
	@include respond-to('small') {
		float: none;
		margin: 30px auto 0;
	}
	li {
		& > a {
			//min-height: 70px;
			//min-width: 90px;
			padding: 10px !important;
		}
	}
}

.tabs {
	& > ul {
		display: flex;
		border-bottom: 0;
		& > li {
			flex: 1 1 auto;
			margin-#{$left}: 10px;
			&:active {
				border: 0;
			}
			& > a {
				position: relative;
				padding: 9px 0;
				border: 2px solid transparent;
				text-align: center;
				color: #777777;
				font-size: 30px;
				font-weight: 300;
				line-height: 1;
				@include respond-to('medium') {
					font-size: 22px;
				}
				&:hover {
					z-index: 5;
					border: 2px solid #a2bb3b;
					color: #777777;
					background-color: #ffffff;
					@include border-radius(25px);
				}
			}
			&.active > a,
			&.active > a:hover,
			&.active > a:focus {
				z-index: 5;
				border: 2px solid #a2bb3b;
				color: #000000;
				@include border-radius(25px);
				&::after,
				&::before {
					position: absolute;
					top: 100%;
					left: 50%;
					width: 0;
					height: 0;
					border: solid transparent;
					content: " ";
					pointer-events: none;
				}
				&::after {
					border-width: 10px;
					border-color: rgba(255, 255, 255, 0);
					border-top-color: #ffffff;
					margin-#{$left}: -10px;
				}
				&:before {
					border-width: 13px;
					border-color: rgba(162, 187, 59, 0);
					border-top-color: #a2bb3b;
					margin-#{$left}: -13px;
				}
			}
		}
	}
}

//  Responsive Tabs 

.nav-tabs-responsive {
	@include respond-to('small') {
		& > li {
			display: none;
			width: 23%;
			& > a {
				width: 100%;
				text-align: center;
				vertical-align: top;
				@include ellipsis();
			}
			&.active {
				width: 54%;
				&:first-child {
					margin-#{$right}: 23%;
				}
				&:last-child {
					margin-#{$left}: 23%;
				}
			}
			&.active,
			&.prev,
			&.next {
				display: block;
			}
			&.prev,
			&.next {
				transform: scale(0.9);
			}
			&.next > a,
			&.prev > a {
				transition: none;
				& .text {
					display: none;
				}
				&:after,
				&:after {
					@include icon-styles();
				}
			}
			&.prev > a:after {
				content: "\e080";
			}
			&.next {
				margin-#{$left}: 0;
				& > a:after {
					content: "\e079";
				}
			}
		}
	}
	@include respond-to('extra-small') {
			& > li {
			width: 18%;
			&.active {
				width: 64%;
				&:first-child {
					margin-#{$right}: 18%;
				}
				&:last-child {
					margin-#{$left}: 18%;
				}
			}
		}
	}
}

.tab-pane-btn {
	display: block;
	padding: 10px 20px;
	text-align: center;
	@include border-radius(25px);
	@include respond-to('small') {
		margin-top: 10px;
	}
	& > i {
	@include transition(all 0.4s ease);
		//font-size: 16px;
		margin-#{$right}: 8px;
	}
	&:hover {
		& > i {
			@include transform(translateX(-4px));
		}
	}
}

.tab-pane-btn_green {
	color: #ffffff;
	background-color: #a2bb3b;
	font-size: 16px;
}

.tab-pane-btn_effect-active {
	&:active {
		@include btn-effect-active;
	}
}

.tab-pane-btn_size-190 {
	width: 190px;
}

//#################TABS CONTENT###################

.tab-content {
	margin-top: 30px;
	border-top: 1px solid #cccccc;
}

.tab-content-hr {
	margin-top: 35px;
	margin-bottom: 20px;
	border-top: 1px solid #cccccc;
}

.tab-content-product-item {
	position: relative;
	margin: 0;
	border: 0;
	background-color: #ffffff;
}


.tabs-product-item {
	max-width: 820px;
	padding-#{$right}: 2px;
	& > ul {
		& > li {
			margin: 0;
			@include respond-to('extra-small') {
				margin-bottom: -3px;
			}
			&:active {
				border: 0;
			}
			& > a {
				position: relative;
				padding: 19px 0;
				border: 0 solid transparent;
				text-align: center;
				color: #2f2f2f;
				font-size: 22px;
				font-weight: normal;
				line-height: 1;
				&:hover {
					border: 0;
					color: #ed1c24;
					background-color: #ffffff;
					@include border-radius(0);
				}
			}
			&:hover {
				box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
			}
			&.active {
				box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
			}
			&.active > a,
			&.active > a:hover,
			&.active > a:focus {
				border: 0;
				color: #ed1c24;
				@include border-radius(0);
				&:after,
				&:before {
					display: none;
				}
			}
		}
	}
}
