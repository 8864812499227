.side-menu {
    width: 300px;
    position: absolute;
    top: 20px;
    right: 0;

}

.page-content {
    margin-right: 300px;
}
