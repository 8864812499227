.articles {
	&__item {
		border-bottom: 1px solid #d3d3d3;
		&:last-child {
			border-bottom: none;
		}
	}
}

.article-item {
	font-size: 0;
	padding: 50px 0;
	@include respond-to('medium') {
		padding: 30px 0;
	}
	&__photo {
		display: inline-block;
		vertical-align: top;
		font-size: initial;
		border-#{$left}: 2px solid $accent;
		padding-#{$left}: 10px;
		width: 22%;
		@include respond-to('1100') {
			width: 26%;
		}
		@include respond-to('medium') {
			width: 28%;
		}
		@include respond-to('small') {
			width: 100%;
			border-#{$left}: none;
			margin-bottom: 15px;
			padding-bottom: 15px;
			text-align: center;
		}
	}
	&__img {
		position: relative;
		display: block;
		max-width: 220px;
		width: 100%;
		height: 220px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		box-shadow: 0 0 15px 0 rgba($color: #000000, $alpha: 0.2);
		@include respond-to('medium') {
			max-width: 200px;
			height: 200px;
		}
		@include respond-to('small') {
			margin: 0 auto;
		}
	}
	&__default-img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 95%;
		max-height: 95%;
	}
	&__info {
		// width: 82%;
		width: 78%;
		display: inline-block;
		vertical-align: top;
		padding-#{$right}: 65px;
		font-size: initial;
		@include respond-to('1100') {
			width: 74%;
		}
		@include respond-to('medium') {
			padding-#{$right}: 35px;
			width: 72%;
		}
		@include respond-to('small') {
			width: 100%;
			text-align: center;
			padding-#{$right}: 0;
		}
	}
	&__title {
		position: relative;
		margin-bottom: 20px;
		font-size: 30px;
		font-weight: 900;
		color: #000;
		line-height: 1;
		@include respond-to('small') {
			display: inline-block;
			font-size: 25px;
		}
		@include respond-to('extra-small') {
			font-size: 22px;
		}
		&::after {
			content: '';
			position: absolute;
			#{$right}: -30px;
			top: 0;
			height: 2px;
			width: 180px;
			background-color: $accent;  
			@include respond-to('medium') {
				#{$right}: -15px;
			}
			@include respond-to('small') {
				right: auto;
				left: 50%;
				transform: translateX(-50%);
				margin: 0 auto;
				top: -10px;
			}
		}
	}
	&__text {
		font-size: 20px;
		font-weight: 400;
		color: #000;
		@include respond-to('small') {
			font-size: 18px;
		}
		& p {}
	}
	&__btn {
		& a {
			display: inline-block;
			padding-bottom: 5px;
			border-bottom: 2px solid $accent;
			font-size: 18px;
			font-weight: 900;
			@include link_no-hover(#000);
		}
	}
}