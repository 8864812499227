.breadcrumb {
	background-color: transparent;
	margin-top: -45px;
	padding: 8px 0px;
	z-index: 5;
	@include respond-to('small') {
		display: none;
	}
	& li {
		font-size: 16px;
		&::before {
			color: #fff;
			z-index: 5;
		}
		& a {
			text-decoration: none;
			color: #938f8d;
			font-size: 18px;
			font-weight: 500;
		}
	}
	& .active {
		color: #ffffff;
		z-index: 5;
	}
}
