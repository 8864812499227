$icon-hamburger: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAARCAYAAADHeGwwAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAXxJREFUeNq0VEtKA0EQ7Z6MGcdPRAV1oYhLb6CiB/AG7jyIx/AQ7j2BOxe6dJGVKIgYAvEbEyUz7St4LeUwrSNMCl6mpmtSn1dVbZ1zq8aYHWDF/JQcmAYWTLmktLmAXc7vLQJcQ9kw45GOBDiAsg9IJTNAAkwFMpOzF1bnpQu8AxH1Ae13wKkEKIssAUZ0aPmHXFFXWUIBRGaBSToUvucYMGLQkGRAD3gChjF+toEjOpxnY30TYzqd4Pt3YqGE+X2ftF1IBTdQ1mtqak5qI+JKAuxCWWNpf1JKJ11+bws2oeVN0Xj7Ww9qkZjRNlV2KaeobLGW/jFF4rcnAc6APTZnxIbWJZkEuARawDJXP6+Y5TOXqgF8AI9kQSbolcke+x4k3OKUpbnC9mrdvw/pxPI5oJ5VXbSQNNXU2ALn4vxTJyUULQKHvPAadOBvyUTdpk7ZbGCEPVXyfBD6pYJzKFtjmtK+VHACtNWal167zKpsFDvqNtUbLeftLwEGAF6PfhNOZLHFAAAAAElFTkSuQmCC');

.wrap-cat-box {
	display: block;
	//max-width: 680px;
	width: 100%;
	position: relative;
	margin-bottom: 30px;
	@include respond-to('medium') {
		margin: 0 auto 20px;
	}
	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(106, 93, 135, 0.9);
		opacity: 0;
		transition: all 0.3s;
	}
	&:hover {
		.cat-box-section-hidden {
			display: block;
			@include respond-to('extra-small') {
				display: none;
			}
		}
		&::after {
			opacity: 1;
		}
	}
	&_wide {
		height: 630px;
		@include respond-to('small') {
			height: 300px;
		}
		@include respond-to('extra-small') {
			height: 200px;
		}
	}
	&_narrow {
		height: 300px;
		@include respond-to('extra-small') {
			height: 200px;
		}
	}
}

.cat-box-data {
	position: absolute;
	bottom: 25px;
	#{$right}: 0;
	width: 100%;
	padding-#{$right}: 30px;
	z-index: 1;
	@include respond-to('extra-small') {
		padding-#{$right}: 20px;
	}
	&__title {
		font-size: 26px;
		font-weight: 900;
		color: #fff;
		line-height: 1;
		position: relative;
		padding-#{$right}: 44px;
		@include respond-to('extra-small') {
			font-size: 20px;
			padding-#{$right}: 30px;
		}
		&::after {
			content: "";
			position: absolute;
			top: 4px;
			#{$right}: 0;
			background: $icon-hamburger no-repeat;
			width: 24px;
			height: 17px;
			@include respond-to('extra-small') {
				top: 1px;
			}
		}
	}
}

.cat-box-section-hidden {
	display: none;
	margin-top: 15px;
	padding: side-values(0 43px 0 90px);
	@include respond-to('extra-small') {
		padding: 0;
	}
	&__txt {
		font-size: 18px;
		font-weight: 300;
		color: #fff;
		line-height: 1.3;
		@include respond-to('small') {
			font-size: 16px;
		}
	}
	&__btn {
		font-size: 18px;
		font-weight: 300;
		color: #fff;
		border: 1px solid #fff;
		padding: 5px 19px;
		display: inline-block;
		margin-top: 15px;
		@include respond-to('extra-small') {
			padding: 5px 10px;
			font-size: 14px;
		}
	}
}
