.wrapper-all-content {
	background-color: #fff;
	position: relative;
	width: 100%;
	height: 750px;
	@include respond-to ('1100') {
		height: 650px;
	}
	@include respond-to ('medium') {
		height: 550px;
	}
}

.about {
	background: url(../images/bg.jpg);
	background-position: 100%;
	background-repeat: no-repeat;
	min-height: 700px;
	width: 90%;
	position: relative;
	z-index: 150;
	top: -150px;
	right: 0;
	@include respond-to('huge') {
		top: -125px;
	}
	@include respond-to('large') {
		top: -35px;
	}
	@include respond-to ('medium') {
		min-height: 500px;
	}
	@include respond-to ('small') {
		width: 100%;
		top: 0;
	}
}

.about-info {
	background-color: transparent;
	z-index: 170;
	padding-top: 50px;
	margin-bottom: 50px;
	@include respond-to('extra-small') {
		margin-top: 0;
	}
	& .title {
		font-size: 40px;
		color: #636363;
		@include respond-to ('medium') {
			font-size: 30px;
		}
		@include respond-to ('small') {
			margin-#{$right}: 15px;
		}
	}
	& p {
		font-size: 18px;
		color: #636363;
		width: 85%;
		line-height: 1.2;
		white-space: pre-line;
		@include respond-to ('medium') {
			font-size: 16px;
		}
		@include respond-to ('small') {
			margin-#{$right}: 15px;
		}
	}
	& a {
		font-size: 18px;
		color: #ceaf78;
		font-weight: 700;
		line-height: 2;
		@include respond-to ('medium') {
			font-size: 16px;
		}
		@include respond-to ('small') {
			margin-#{$right}: 15px;
		}
		&:hover {
			text-decoration: none;
		}
		&:focus,
		&:active {
			text-decoration: none;
		}
	}
}

.slid-item {
	width: 90%;
	float: left;
	height: 500px;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	@include respond-to ('1100') {
		height: 400px;
	}
	@include respond-to ('medium') {
		height: 300px;
	}
	@include respond-to ('small') {
		height: 200px;
	}
}

.slick-track {
	width: 90%;
}

.hp-slide {
	width: 90%;
	float: #{$left};
	z-index: 200;
	margin-top: 25px;
	margin-bottom: -50px;
	@include respond-to ('1550') {
		width: 1300px;
	}
	@include respond-to ('huge') {
		width: 1200px;
	}
	@include respond-to('larger') {
		width: 1100px;
	}
	@include respond-to('large') {
		width: 1000px;
	}
	@include respond-to ('medium') {
		width: 800px;
	}
	@include respond-to ('small') {
		width: 100%;
		z-index: 200;
		margin: 0 auto;
	}

}

.slick-prev {
	top: -45px;
	position: absolute;
	z-index: 230;
	height: 90px;
	background-color: rgba(#fff, 0.6);
	width: 150px;
	right: 150px;
	left: auto;
	@include respond-to ('medium') {
		height: 75px;
		width: 100px;
		top: -38px;
		right: 100px;
	}
	@include respond-to ('extra-small') {
		height: 60px;
		top: -30px;
	}
	[dir="rtl"] & {
		left: 150px;
		right: auto;
		background-color: rgba(#fff, 0.7);
		@include respond-to ('medium') {
			left: 100px;
		}
	}
	&:hover {
		background-color: rgba(#fff, 0.7);
	}
	&::active {
		background-color: rgba(#fff, 0.7);
	}
	&:before {
		content: url('../images/arr-right.png');
		[dir="rtl"] & {
			content: url('../images/arr-left.png');
			padding-right: 50px;

		}
	}
}

.slick-next {
	top: -45px;
	position: absolute;
	z-index: 230;
	background-color: rgba(#fff, 0.6);
	height: 90px;
	width: 150px;
	right: 0;
	margin: 0;
	@include respond-to ('medium') {
		height: 75px;
		width: 100px;
		top: -38px;
	}
	@include respond-to ('extra-small') {
		height: 60px;
		top: -30px;
	}
	[dir="rtl"] & {
		left: 0;
		right: auto;
		background-color: rgba(#fff, 0.7);
	}
	&:hover {
		background-color: rgba(#fff, 0.7);
	}
	&:active {
		background-color: rgba(#fff, 0.7);
	}
	&:before {
		content: url('../images/arr-left.png');
		[dir="rtl"] & {
			content: url('../images/arr-right.png');
			padding-left: 50px;
		}
	}
}