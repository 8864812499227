.lpage-section-limit{
  max-width: 1750px;
  width: 100%;
  margin: 0 auto;
	padding: 0 15px;
}

.lpage-wrapper{
  position: relative;
  &:after{
    content: "";
    
  }
}

.lpage-wrapper-shadow{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
}

.lpage-form-box{
  background-color: rgba(255,255,255,0.3);
  box-shadow: 10px 10px 30px 0px rgba(0,0,0,1);
  padding: 50px 45px 30px;
  text-align: center;
  margin-bottom: 60px;
  @include respond-to('1550'){
    padding: 20px;
  }
  @include respond-to('small'){
    padding: 20px 0;
    margin-bottom: 20px;
  }
  &__title{
    font-size: 35px;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    @include respond-to('small'){
      font-size: 20px;
    }
  }
  &__sub-title{
    font-size: 35px;
    line-height: 1;
    color: #fff;
    margin-bottom: 35px;
    @include respond-to('small'){
      font-size: 20px;
    }
  }
  & .custom-form {
    & .customCheckboxText{
      color: #fff;
      font-size: 18px;
      margin-bottom: 20px;
    }
    & .form-group{
      margin-bottom: 15px;
    }
    & .form-control{
      height: 50px;
      &::placeholder{
        color: #3d3d3d;
      }
    }
    & .btn-form{
      height: 50px;
      margin-bottom: 30px !important;
    }
  }
}

.lpage-title-after-form{
  color: #fff;
  font-size: 29px;
  line-height: 1;
  margin: 0;
}

.lpage-phone-after-form{
  color: #fff;
  font-size: 40px;
  line-height: 1;
  font-weight: bold;
  &:hover,&:focus{
    color: #fff;
  }
}

.lpage-section-right{
  padding: 60px 0 0;
  @include respond-to('1550'){
    padding-top: 20px;
  }
  @include respond-to('medium'){
    padding-top: 0px;
  }
  & .container-fluid{
    padding: 0;
  }
}

.lpage-content{
  padding: 0 60px 0 0px;
  @include respond-to('1550'){
    padding: 0 20px 0 60px;
  }
  @include respond-to('larger'){
    padding: 20px;
  }
  @include respond-to('small'){
    padding: 0;
  }
  & h1{
    font-size: 54px;
    font-weight: bold;
    color: #f38530;
    line-height: 1;
    margin: 0;
    @include respond-to('1550'){
      font-size: 40px;
    }
    @include respond-to('larger'){
      font-size: 35px;
    }
    @include respond-to('small'){
      font-size: 24px;
    }
  }
  & h2{
    font-size: 33px;
    color: #f38530;
    line-height: 1;
    margin-top: 0;
    margin-bottom: 20px;
    @include respond-to('1550'){
      font-size: 30px;
    }
    @include respond-to('larger'){
      font-size: 25px;
    }
    @include respond-to('small'){
      font-size: 19px;
    }
  }
  & p{
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    @include respond-to('small'){
      font-size: 16px;
    }
  }
  & ul{
    padding: 0;
    margin: 0;
    & li{
      font-size: 20px;
      font-weight: bold;
      color: #f38530;
    }
  }
}

.lpage-logo{
  @include respond-to('small'){
    margin: 20px 0; 
  }
  & img{
    @include respond-to('small'){
      max-width: 100px;
      margin-left: 10px;
    }
  }
}

.lpage-logo-text{
  font-size: 30px;
  font-weight: bold;
  color: #f38530;
  line-height: 1.1; 
  margin-top: 10px;
  text-align: center; 
  @include respond-to('large'){
    font-size: 16px;
  }
  @include respond-to('small'){
    text-align: right;
    font-size: 18px;
  }
}

.section-space70{
  height: 40px;
  @include respond-to('medium'){
    height: 20px;
  }
}