$btn-cart: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPCAYAAAACsSQRAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjMzN0RBMEI5OEYyMTExRThBMjBCQjE3OUVEQ0NDOUI0IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjMzN0RBMEJBOEYyMTExRThBMjBCQjE3OUVEQ0NDOUI0Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzM3REEwQjc4RjIxMTFFOEEyMEJCMTc5RURDQ0M5QjQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzM3REEwQjg4RjIxMTFFOEEyMEJCMTc5RURDQ0M5QjQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5fLadoAAABIElEQVR42mJs3RU1l4GBYQkQ72cgA1S5LmVgAtKOQLwPiA9A2SQDkCEGQJwDxLJQw/qBmJFUQz4B8VQg1gbiPUBcAMTspBjCgsT+AcS7gNgFiBOB+DkBvf+B+Hfb7uiLLGgSj6H0NBIcMhfdkIdQuh4aY/hAGBC3A/EVdENAXvgNxDZA/IeAV9yB+CMQr0c35DUQvwNiZyA2xGMIKPaEgXgdMJ08RDfkKxC/hLrIDI8h1tDEuRY9dmDgDhAHAvFBPIZIQ72yFZchq4BYC+pcDrQ08xUprCZBDcJqyEogPgPEwVAnX4GGASgwPYBYAYgXAfFbbIkNGcyAJrqbUFf9A2JdUEBC5ZmBuAc52WMD76D0ByQ1v4D4C5T9BVkxQIABAGx6QPWzyveTAAAAAElFTkSuQmCC');

.product-box {
	display: block;
	position: relative;
	height: 400px;
	max-width: 270px;
	margin-bottom: 40px;
	margin-right: auto;
	margin-left: auto;
	padding: 5px 25px;
	border: 1px solid #717171;
	background-color: #fff;
	transition: box-shadow .2s ease, transform .2s ease;
	@include respond-to('small') {
		margin-bottom: 20px;
	}
	&:hover,
	&:focus,
	&:active {
		box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
		border: none;
		transform: scale(1.05);
		text-decoration: none;
		& .btn-with-cart__text {
			background-color: $accent;
			color: #fff;
		}
	}
	&__img {
		position: relative;
		height: 195px;
		border-bottom: 1px solid #717171;
		overflow: hidden;
		padding-bottom: 5px;
		& img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			max-height: 185px;
		}
	}
	&__label {
		position: absolute;
		left: -6px;
		top: -6px;
		z-index: 1;
		overflow: hidden;
		width: 92px;
		height: 92px;
		text-align: #{$right};
		& span {
			font-size: 14px;
			font-weight: bold;
			color: #FFF;
			// text-transform: uppercase;
			text-align: center;
			line-height: 25px;
			transform: rotate(-45deg);
			width: 100px;
			display: block;
			background: $accent;
			// background: linear-gradient(#9BC90D 0%, #1e5799 100%);
			box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
			position: absolute;
			top: 15px;
			left: -23px;
			&::before {
				content: "";
				position: absolute;
				#{$left}: 0px;
				top: 100%;
				z-index: -1;
				border-#{$left}: 3px solid $accent;
				border-#{$right}: 3px solid transparent;
				border-bottom: 3px solid transparent;
				border-top: 3px solid $accent;
			}
			&::after {
				content: "";
				position: absolute;
				#{$right}: 0px;
				top: 100%;
				z-index: -1;
				border-#{$left}: 3px solid transparent;
				border-#{$right}: 3px solid $accent;
				border-bottom: 3px solid transparent;
				border-top: 3px solid $accent;
			}
		}
	}
}

.label-text {
	// position: absolute;
	// top: if-rtl(22px, 20px);
	// left: if-rtl(8px, 15px);
	// transform: rotate(-45deg);
	// color: #fff;
}

.product-box-info {
	padding-top: 15px;
	text-align: center;
	&__title {
		margin-bottom: 10px;
		font-size: 18px;
		font-weight: 700;
		color: $accent;
		line-height: 1;
		max-height: 37px;
		overflow: hidden;
	}
	&__text {
		max-width: 155px;
		max-height: 38px;
		margin: 0 auto;
		overflow: hidden;
		font-size: 14px;
		color: $text;
	}
	&__price {
		padding-top: 10px;
	}
	&__price-desc {
		font-size: 16px;
		font-weight: 700;
		color: $accent;
		line-height: 1;
	}
	&__price-number {
		font-size: 16px;
		font-weight: 700;
		color: $accent;
		line-height: 1;
	}
	&__button {
		position: absolute;
		bottom: 20px;
		left: 0;
		right: 0;
		margin: 0 auto;
	}
}

.btn-with-cart {
	background-color: transparent;
	width: 107px;
	height: 40px;
	&__text {
		float: left;
		width: 66.6%;
		height: 100%;
		padding: 8px;
		border: 1px solid $accent;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		font-size: 16px;
		font-weight: 700;
		color: $accent;
		transition: color .2s ease, background .2s ease;
	}
	&__cart {
		float: right;
		width: 33.3%;
		height: 100%;
		border: 1px solid $accent;
		border-left: none;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
		background-image: $btn-cart;
		background-position: center;
		background-repeat: no-repeat;
	}
}