.main-header {
	width: 100%;
	z-index: 99;
	position: fixed;
	top: 0;
	background: rgba(#fff, 0.9);
	transition: all 0.4s ease;
}

.header-desktop {
	height: 80px;
	@include respond-to('medium') {
		display: none;
	}
}

.logo {
	float: $right;
	position: relative;
	margin-top: -3px;
    margin-right: -10px;
	& > a {
		display: inline-block;
		& > img {
			@include respond-to ('larger'){
				width: 80%;
			}
			@include respond-to ('large'){
				width: 70%;
			}
			@include respond-to ('1100'){
				width: 60%;
				padding-top: 10px;
			}
		}
	}
	

}

.bg-logo {
	box-shadow: if-rtl(15px 10px 14px 0px rgba(34, 60, 80, 0.2), -20px 10px 14px 0px rgba(34, 60, 80, 0.2));
	position: absolute;
	#{$right}: 0;
    border-bottom: 95px solid #fff;
    border-#{$left}: 55px solid transparent;
	width: 31%;
	@include respond-to ('1550'){
		width: 26%;
	}
}

.header-mobile {
	display: none;
	height: 70px;
	@include respond-to('medium') {
		display: block;
	}
	&__logo {
		margin-#{$left}: 15px;
		margin-top: 12.5px;
		float: $left;
		& > img {
			width: 200px;
			@include respond-to ('mobile-m') {
				width: 175px;
    			padding-top: 7.5px;
			}
		}
	}
	&__tel {
		display: inline-block;
		margin-top: 17px;
		i {
			color: #000;
			font-size: 32px;
		}
	}
}

.fa-facebook-f {
	color: #ceaf78;
	float: $left;
	font-size: 23px;
	padding-top: 7px;
	@include respond-to ('1100'){
		padding-top: 4px;
	}
}

.hp-navi{
	text-align: center;
	padding-top: 25px;
}

.main-navi {
	display: inline-block;
	& > ul {
		display: inline-block;
		list-style: none;
		padding: 0;
		& > li {
			float: $right;
			position: relative;
			&:last-child {
				& > a {
					&:after{
						content: "";
						display: inline-block;
						height: 25px;
						width: 1px;
						background: transparent;
						margin: 0 22.5px -5px;
						@include respond-to ('larger'){
							margin: side-values(0 15px -5px);
						}
						@include respond-to ('1100'){
							margin: side-values(0 0 -6px);
						}
					}
				}
			}
			& > a {
				font-size: 18px;
				color: #636363;
				display: block;
				border: 1px solid transparent;
				font-weight: 700;
				padding-bottom: 28px;
				@include respond-to ('large'){
					font-size: 16px;
				}
				&:hover {
					text-decoration: none;
					color: #ceaf78;
				}
				&:focus,
				&:active {
					text-decoration: none;
					color: #ceaf78;
				}
				&:after{
					content: "";
					display: inline-block;
					height: 25px;
					width: 1px;
					background: #bababa;
					margin: 0 22.5px -7.5px;
					@include respond-to ('larger'){
						margin: side-values(0 15px -7.5px);
					}
					@include respond-to ('1100'){
						margin: side-values(0 10px -7.5px);
					}
				}
			}
		}
	}
}

.submenu {
    position: absolute;
    top: 100%;
    #{$left}: -45px;
    width: 180px;
    z-index: 10;
    -webkit-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    display: none;
}

.main-navi ul li:hover .submenu {
	display: block;
	background: rgba(206,175,120,.9);
    list-style: none;
    text-align: $right;
	padding: 10px 20px;
	&:before {
		content: "";
		border: 10px solid transparent;
		border-bottom-color: rgba(206,175,120,.9);
		position: absolute;
		top: -20px;
		width: 0;
		#{$right}: 30px;
		}
	& li {
			&:last-child {
				&:after{
					content: "";
					display: none;
				}
			}
			&:after{
				content: "";
				display: block;
				height: 1px;
				width: 140px;
				background: #ead4ad;
				margin: 5px 0;
			}
			& a {
				font-size: 20px;
				color: #fff;
				display: block;
				border: 1px solid transparent;
				font-weight: 300;
				line-height: 1;
				text-decoration: none;
				padding: 10px 15px 10px 0;
			}
	}	
}

